
import { mapGetters } from 'vuex'
// import GoogleMap from '@/components/contacts/GoogleMap'
import LocationModal from '@/components/modals/LocationModal'

import location from '@/mixins/location.js'

export default {
  components: {
    // GoogleMap,
    LocationModal
  },

  mixins: [
    location
  ],

  data: () => ({
    isActive: false
  }),

  computed: {
    ...mapGetters({
      location: 'location'
    })
  },

  // mounted () {
  //   if (this.location.city === null || this.location.city.id === null) {
  //     this.getLocation()
  //   }
  // },

  methods: {
    toggleModal () {
      this.isActive = !this.isActive

      // this.getLocation()
    }
  }
}
